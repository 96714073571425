/* eslint-disable no-console */
const redirectHandler = (props) => {
  const {
    responseType,
    redirectURL,
    downloadFileURL,
    linkOpenType,
    cookieValidity,
    popupVideo,
    popupVideoURL,
    popupVideoUploadURL,
    customVideoScript,
    defaultClass,
    embeddedFormId,
    currentPostId,
    isParentBlockGatedContentBlock,
    downloadButtonLabel,
  } = props;
  if (responseType === 'redirect' && redirectURL) {
    window.open(redirectURL, linkOpenType);
  } else if (responseType === 'download' && downloadFileURL) {
    window.open(downloadFileURL, '_blank');
  } else if (
    responseType === 'redirectDownload' &&
    redirectURL &&
    downloadFileURL
  ) {
    window.open(downloadFileURL, '_blank');
    window.open(redirectURL, linkOpenType);
  } else if (responseType === 'resource-same-page') {
    //only set cookies when parent block is content gater block
    if (isParentBlockGatedContentBlock) {
      const date = new Date();
      date.setTime(date.getTime() + cookieValidity * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = `postId_${currentPostId}=true; ${expires} ; path=/`;
    }

    setTimeout(function () {
      window.location.reload(true);
    }, 1000);
  } else if (responseType === 'popup') {
    if (
      (popupVideo === 'url' && popupVideoURL) ||
      (popupVideo === 'upload' && popupVideoUploadURL) ||
      (popupVideo === 'embedded' && customVideoScript)
    ) {
      document
        .getElementById(`${defaultClass}_fancybox_${embeddedFormId}`)
        .click();
    }
  } else if (responseType === 'thankYouDownloadCta') {
    const cookieName = `hs_form_${currentPostId}`;
    const date = new Date();
    date.setTime(date.getTime() + 60 * 10 * 1000);
    document.cookie = `${cookieName}=true;expires=${date.toUTCString()}; path=/`;
    const encodedParams = btoa(downloadFileURL);
    const encodedPostId = btoa(currentPostId);
    const label =
      downloadButtonLabel && downloadButtonLabel.trim()
        ? downloadButtonLabel.trim()
        : '';
    window.open(
      `${redirectURL}?file=${encodedParams}&postId=${encodedPostId}&label=${encodeURIComponent(
        label,
      )}`,
      '_self',
    );
  }
};

export default redirectHandler;
